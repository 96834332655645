import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { Grid, Fab } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FullScreenImage from '../../components/FullScreenImage/FullScreenImage';
import HomePage2Content from './HomePages/HomePage2Content';
import HomePage3Content from './HomePages/HomePage3Content';
import './styles.css';

const Home = ({ auth }) => {
  const history = useHistory();
  const scrollContainerRef = useRef(null);

  // Track which "snap page" the user is on
  const [currentPage, setCurrentPage] = useState(0);

  // Scroll event handler for the container
  const handleScroll = () => {
    if (!scrollContainerRef.current) return;

    // How tall is one "screen"
    const pageHeight = window.innerHeight;
    // Current scroll position in container
    const scrollPosition = scrollContainerRef.current.scrollTop;

    // Convert to snap-page index (0, 1, 2...)
    const newPage = Math.round(scrollPosition / pageHeight);

    // Update state on every scroll
    setCurrentPage(newPage);
    // Log it on EVERY scroll
    console.log('Container is scrolling → currentPage:', newPage, 'scrollY:', scrollPosition);
  };

  const scrollToPage = (page) => {
    const section = document.getElementById(`page-${page}`);
    if (section && scrollContainerRef.current) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div style={{ height: '100vh', overflow: 'hidden' }}>
      {/* Use our custom scrollable container */}
      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        className="home-page"
        style={{
          height: '100%',
          overflowY: 'scroll',
          scrollSnapType: 'y proximity',
        }}
      >
        <Layout currentPage={currentPage}>
          {/* Page 0 (the first snap) */}
          <div id="page-0" className="snap-page" style={{ scrollSnapAlign: 'start' }}>
            <div className="home-page-intro-container">
              <Grid container justifyContent="flex-start" alignItems="center" md={6} xs={12}>
                <h1>Geospatial intelligence based on radio spectrum sensing</h1>
                <Fab
                  variant="extended"
                  className="home-action-button"
                  style={{ textTransform: 'none', zIndex: 100, borderRadius: '1px' }}
                  onClick={() => scrollToPage(1)}
                >
                  Learn more
                  <ArrowForwardIosIcon />
                </Fab>
              </Grid>
            </div>
            <div className="scroll-down" onClick={() => scrollToPage(1)}>
              <ExpandMoreIcon style={{ fontSize: '48px' }} />
            </div>
            <FullScreenImage />
          </div>

          {/* Page 1 (the second snap) */}
          <div
            id="page-1"
            className="snap-page"
            style={{
              backgroundColor: '#ffffff',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              scrollSnapAlign: 'start',
            }}
          >
            <HomePage2Content />
            <div className="scroll-down" onClick={() => scrollToPage(2)}>
              <ExpandMoreIcon style={{ fontSize: '48px' }} />
            </div>
          </div>

          {/* Page 2 (the third snap) */}
          <div
            id="page-2"
            className="snap-page"
            style={{ backgroundColor: '#ffffff', height: '100vh', scrollSnapAlign: 'start' }}
          >
            <HomePage3Content />
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default Home;
