import { OPEN_CONTACT_DIALOG, CLOSE_CONTACT_DIALOG } from '../actions/dialogActions';

const initialState = {
  isContactDialogOpen: false,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_CONTACT_DIALOG:
      return {
        ...state,
        isContactDialogOpen: true,
      };
    case CLOSE_CONTACT_DIALOG:
      return {
        ...state,
        isContactDialogOpen: false,
      };
    default:
      return state;
  }
};

export default dialogReducer;