import React from 'react';
import PropTypes from 'prop-types';
import Navbar from '../components/Navbar/Navbar';
import './styles.css';

const Layout = ({ children, currentPage }) => {
  return (
    <>
      {/* Pass currentPage to Navbar */}
      <Navbar currentPage={currentPage} />
      <div className="container">{children}</div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default Layout;
