import React from "react";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { openContactDialog } from '../../../store/actions/dialogActions';
import '../../Pricing/styles.css';

const HomePage3Content = ({ openContactDialog }) => {

  const dispatch = useDispatch();

  return (
    <div className="pricing-content">
      <div className="pricing-container">
        <div className="pricing-tile">
        <div className="plan-header">
            <h2>Base Plan</h2>
            <span className="beta-tag">Alpha</span>
          </div>
          <ul>
            <li>Dynamic Geo-Tagged RF data</li>
            <li>$0.01 per API call</li>
            <li>Options to choose specific data</li>
            <li>Email support</li>
          </ul>
          <button onClick={() => dispatch(openContactDialog)}>Choose Plan</button>
        </div>
        <div className="pricing-tile">
        <div className="plan-header">
          <h2>Enterprise / Custom</h2>
          </div>
          <ul>
            <li>Custom RF mapping</li>
            <li>Advanced insight</li>
            <li>Priority support</li>
          </ul>
          <button onClick={() => dispatch(openContactDialog)}>Contact Us</button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  openContactDialog,
};

export default connect(null, mapDispatchToProps)(HomePage3Content);