import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { openContactDialog, closeContactDialog } from '../../store/actions/dialogActions';

import { isMobile } from 'react-device-detect';
import Fab from '@material-ui/core/Fab';
import Container from '@material-ui/core/Container';
import LogoWhite from '../../images/Oroara_logo_white.png';
import LogoDark from '../../images/Oroara_logo.png';

import {
  AppBar,
  Toolbar,
  Button,
  Drawer,
  List,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import './styles.css';

const Navbar = ({ openContactDialog, closeContactDialog, isContactDialogOpen, currentPage }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [contactFormData, setContactFormData] = useState({
    description: '',
    name: '',
    contact: ''
  });
  const [submitContactStatus, setSubmitContactStatus] = useState(null);
  const history = useHistory();
  const location = useLocation();

  // If you're on snap page-1 or on "/about", show "Get Access" button
  const showStandaloneButton = currentPage === 1 || location.pathname === '/about';

  // Decide which logo theme based on snap page
  const getLogoTheme = () => {
    // Use LogoWhite only on page-0. Otherwise, LogoDark
    if (currentPage === 0) {
      return LogoWhite;
    }
    return LogoDark;
  };

  const handleContactFormChange = (e) => {
    setContactFormData({
      ...contactFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleContactFormSubmit = () => {
    emailjs
      .send('service_5a79la3', 'template_qbyuf7n', contactFormData, '1eJ3ws1YtOIGQ30s4')
      .then(() => {
        setSubmitContactStatus('success');
      })
      .catch((error) => {
        setSubmitContactStatus('error');
        console.log(error);
      });
  };

  // Handle "Get Access" logic
  const handleGetAccessClick = () => {
    if (location.pathname === '/') {
      // Scroll directly to snap page-2 if on home
      const page2 = document.getElementById('page-2');
      if (page2) {
        page2.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      // Otherwise, go to /plans
      history.push('/plans');
    }
  };

  // Handle Logo click: navigate home AND scroll to page-0
  const handleLogoClick = () => {
    if (location.pathname === '/') {
      // Already on home page, just scroll to page-0
      const page0 = document.getElementById('page-0');
      if (page0) {
        page0.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      // Navigate to home, then scroll once the page is loaded
      history.push('/');
      setTimeout(() => {
        const page0 = document.getElementById('page-0');
        if (page0) {
          page0.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 300);
    }
  };

  const navItems = [{ label: 'What we do', link: '/about' }];

  return (
    <Container
      maxWidth={isMobile ? 'xs' : 'xl'}
      style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1000 }}
    >
      <nav className="navbar">
        {/* Logo: Navigates Home + scroll to page-0 */}
        <Fab
          className="logo"
          variant="extended"
          style={{ textTransform: 'none', zIndex: 100, borderRadius: '1px' }}
          onClick={handleLogoClick}
        >
          <img
            src={getLogoTheme()}
            alt="Oroara Logo"
            style={{ height: '30px', transition: '0.3s ease-in-out' }}
          />
        </Fab>

        <div className="nav-links flex-1">
          {/* Only show "Get Access" button if on snap page-1 or /about */}
          {showStandaloneButton && (
            <Fab
              className="standalone-action"
              variant="extended"
              style={{
                backgroundColor: '#5cb85c',
                color: '#fff',
                textTransform: 'none',
                borderRadius: '1px',
                padding: '8px 16px',
                marginRight: '10px',
                zIndex: 1
              }}
              onClick={handleGetAccessClick}
            >
              Get Access
            </Fab>
          )}

          {/* Menu items */}
          <AppBar position="static" className="nav-item-bar">
            <Toolbar className="nav-item-toolbar">
              {isMobile ? (
                <>
                  <IconButton
                    className="nav-menu-icon-btn"
                    color="inherit"
                    onClick={() => setDrawerOpen(true)}
                  >
                    <MenuIcon className="nav-menu-icon" />
                  </IconButton>
                  <Drawer
                    anchor="top"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                  >
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton onClick={() => setDrawerOpen(false)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <List>
                      {navItems.map((item, idx) => (
                        <ListItem
                          button
                          key={idx}
                          onClick={() => {
                            setDrawerOpen(false);
                            history.push(item.link);
                          }}
                        >
                          <div>{item.label}</div>
                        </ListItem>
                      ))}
                    </List>
                  </Drawer>
                </>
              ) : (
                navItems.map((item, idx) => (
                  <Button
                    key={idx}
                    className="nav-item"
                    style={{ textTransform: 'none' }}
                    onClick={() => history.push(item.link)}
                  >
                    {item.label}
                  </Button>
                ))
              )}
            </Toolbar>
          </AppBar>
        </div>

        {/* "Get in Touch" button (desktop only) */}
        {!isMobile && (
          <Fab
            className="action"
            variant="extended"
            style={{
              textTransform: 'none',
              zIndex: 100,
              borderRadius: '1px'
            }}
            onClick={openContactDialog}
          >
            <div>Get in Touch</div>
          </Fab>
        )}
      </nav>

      {/* Contact Dialog */}
      <Dialog open={isContactDialogOpen} onClose={closeContactDialog}>
        <DialogTitle>
          {submitContactStatus === 'success' ? 'Success' : 'Interested? Get in touch'}
        </DialogTitle>
        <DialogContent>
          {submitContactStatus === 'success' ? (
            <div>Message sent successfully! We will get back soon.</div>
          ) : (
            <>
              <TextField
                autoFocus
                margin="dense"
                name="description"
                label="What are you looking to do?"
                type="text"
                fullWidth
                value={contactFormData.description}
                onChange={handleContactFormChange}
              />
              <TextField
                margin="dense"
                name="name"
                label="What's your name?"
                type="text"
                fullWidth
                value={contactFormData.name}
                onChange={handleContactFormChange}
              />
              <TextField
                margin="dense"
                name="contact"
                label="How we can get back to you? Please provide email or phone number"
                type="text"
                fullWidth
                value={contactFormData.contact}
                onChange={handleContactFormChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {submitContactStatus === 'success' ? (
            <Button onClick={closeContactDialog} color="primary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={closeContactDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleContactFormSubmit} color="primary">
                Send
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isContactDialogOpen: state.contactDialog.isContactDialogOpen
});

const mapDispatchToProps = {
  openContactDialog,
  closeContactDialog
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Navbar);
