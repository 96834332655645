import React, { useState, useEffect, useRef } from "react";
import { Textfit } from "react-textfit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { CSSTransition } from "react-transition-group";
import "../../About/styles.css";

const HomePage2Content = () => {
  // Determine mobile view based on window width (adjust breakpoint as needed)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  // For mobile: no default selection; for desktop: default to the first item.
  const initialSelected = window.innerWidth <= 768 ? null : 0;
  const [selectedItem, setSelectedItem] = useState(initialSelected);

  const arrowRef = useRef(null);
  const itemRefs = useRef([]);

  const pia = [
    { title: "Telecommunications", sublist: ["Coverage Mapping", "Spectrum Management", "Interference Detection"] },
    { title: "Defense and Security", sublist: ["RF Surveillance", "Geofencing", "Signal Localization"] },
    { title: "IoT and Smart Cities", sublist: ["Signal Localization"] },
    { title: "Aerospace and Aviation", sublist: ["Navigation Aids", "Satellite Communication"] },
    { title: "Media and Broadcasting", sublist: ["Broadcast Quality Assurance", "Unauthorized Transmissions"] },
    { title: "Research and Academia", sublist: ["Propagation Studies", "Machine Learning Models"] },
  ];

  // Listen for window resize events to update mobile/desktop mode.
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      // When switching views, set default selection accordingly.
      if (mobile) {
        setSelectedItem(null);
      } else {
        setSelectedItem(0);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // For desktop: update arrow position based on selected list item.
  useEffect(() => {
    if (!isMobile && arrowRef.current && itemRefs.current[selectedItem]) {
      const selectedItemEl = itemRefs.current[selectedItem];
      arrowRef.current.style.top = `${selectedItemEl.offsetTop + selectedItemEl.clientHeight / 2 - 12}px`;
    }
  }, [selectedItem, isMobile]);

  return (
    <div className="about-content">
      {/* Section 1 - Dynamic Text */}
      <div className="section1">
        <div className="section1-text-container">
          <Textfit mode="multi" min={22} max={35} style={{ height: "100%" }}>
            We continuously collect and monitor a broad set of RF signals at various altitudes from ground to space, then extract and analyze through our proprietary AI-driven solution to provide powerful intelligence for our customers.
          </Textfit>
        </div>
      </div>

      {/* Section 2 - Industry Applications List */}
      <div className="section2">
        <div className="section2-content">
          <h3 className="section2-header">Potential Industry Applications</h3>

          {isMobile ? (
            // MOBILE VIEW: Render the list; each item expands to show its sublist.
            <div className="list-container">
              {pia.map((item, index) => (
                <div key={index}>
                  <div
                    className={`list-item ${selectedItem === index ? "active" : ""}`}
                    onClick={() =>
                      setSelectedItem(selectedItem === index ? null : index)
                    }
                    ref={(el) => (itemRefs.current[index] = el)}
                  >
                    {item.title}
                  </div>
                  {/* Animate the mobile sublist using CSSTransition */}
                  <CSSTransition
                    in={selectedItem === index}
                    timeout={300}
                    classNames="mobile-sublist"
                    unmountOnExit
                  >
                    <div className="mobile-sublist">
                      <h3 style={{ padding: "20px" }}>{item.title}</h3>
                      <ul>
                        {item.sublist.map((subItem, idx) => (
                          <li key={idx}>{subItem}</li>
                        ))}
                      </ul>
                    </div>
                  </CSSTransition>
                </div>
              ))}
            </div>
          ) : (
            // DESKTOP VIEW: Render the list on the left, arrow, and sublist-box on the right.
            <div className="list-sublist-container">
              {/* Left - List Container */}
              <div className="list-container">
                {pia.map((item, index) => (
                  <div
                    key={index}
                    className={`list-item ${selectedItem === index ? "active" : ""}`}
                    onClick={() => setSelectedItem(index)}
                    ref={(el) => (itemRefs.current[index] = el)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>

              {/* Dynamic Arrow */}
              <div className="arrow-container">
                <KeyboardArrowRightIcon ref={arrowRef} className="arrow" />
              </div>

              {/* Right - Sublist Box */}
              <div className="sublist-box">
                <div className="sublist">
                  <h3 style={{ padding: "20px" }}>{pia[selectedItem].title}</h3>
                  <ul>
                    {pia[selectedItem].sublist.map((subItem, idx) => (
                      <li key={idx}>{subItem}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default HomePage2Content;
