import React from 'react';
import Layout from '../../layout/Layout';
import HomePage2Content from '../Home/HomePages/HomePage2Content';
import './styles.css';

const About = () => {
  return (
    <Layout>
      <div className="about-page">
        <HomePage2Content />
      </div>
    </Layout>
  );
};

export default About;
