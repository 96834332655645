import React from 'react';
import Layout from '../../layout/Layout';
import HomePage3Content from '../Home/HomePages/HomePage3Content';
import './styles.css';

const Pricing = () => {
  return (
    <Layout>
      <div className="pricing-page">
        <HomePage3Content />
      </div>
    </Layout>
  );
};

export default Pricing;
