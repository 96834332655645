import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Home from './pages/Home/Home';
import Profile from './pages/Profile/Profile';
import Users from './pages/Users/Users';
import Admin from './pages/Admin/Admin';
import NotFound from './pages/NotFound/NotFound';
import Loader from './components/Loader/Loader';
import About from './pages/About/About';
import Pricing from './pages/Pricing/Pricing';

import { initGA } from './utils/analytics';

initGA();

//const App = ({ logInUserWithOauth, auth, loadMe }) => {
const App = () => {
  // useEffect(() => {
  //   loadMe();
  // }, [loadMe]);

  //redosled hookova
  // useEffect(() => {
  //   if (window.location.hash === '#_=_') window.location.hash = '';

  //   const cookieJwt = Cookies.get('x-auth-cookie');
  //   if (cookieJwt) {
  //     Cookies.remove('x-auth-cookie');
  //     logInUserWithOauth(cookieJwt);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
  //     loadMe();
  //   }
  // }, [auth.isAuthenticated, auth.token, loadMe, auth.isLoading, auth.appLoaded]);

  return (
    <>
      {/*auth.appLoaded ? (
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/users" component={Users} />
          <Route path="/notfound" component={NotFound} />
          <Route path="/admin" component={Admin} />
          <Route exact path="/:username" component={Profile} />
          <Route exact path="/" component={Home} />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Loader />
      )*/}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/plans" component={Pricing} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//export default compose(connect(mapStateToProps, { logInUserWithOauth, loadMe }))(App);
export default App;
